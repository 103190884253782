<template>
<div>
    <b-row>

        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar"></i> <span class="h5"> Estadísticas </span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA VERIFICAR ACTIVIDADES NECESARIAS EN CHARLA INFORMATIVA:
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-check-double fa-3x pb-1"></i>
                            <br>
                            <span class="lg-numero">{{listaCheckList.length}}</span>
                            <br>
                            <span class="text-muted">Verificar actividades necesarias en charla informativa</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestión de verificar actividades necesarias para charla informativas</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="6" class="my-2">
                            <b-button block variant="custom" @click="modalRegistrarCheckList = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Nuevo check list</span>
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i><span class="h5"> Condiciones de charla informativa registrados </span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col md="3">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table bordered hover show-empty mediun responsive outlined :items="listaCheckList" :fields="camposLote" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template v-slot:cell(opciones)="param">
                                    <b-button size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirModalActualizarCheckList(param)" variant="dark" class=" mr-1 mb-1">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </span>
                                    </b-button>

                                    <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarCheckList(param)" variant="danger">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                </template>
                                <template v-slot:cell(idProyecto)="param">
                                    {{comboProyecto.find(x => x.idProyecto==param.item.idProyecto).nombre}}
                                </template>
                                <template v-slot:cell(cantidadChecksSi)="param">
                                    {{param.item.datosEvaluacion.filter(x => x.evaluacion==2).length}}
                                </template>
                                <template v-slot:cell(cantidadChecksNo)="param">
                                    {{param.item.datosEvaluacion.filter(x => x.evaluacion==1).length}}
                                </template>

                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>
    <CModal :show.sync="modalRegistrarCheckList" size="xl" color="primario">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo check list</span>
            </h6>
            <CButtonClose @click="modalRegistrarCheckList = false" class="text-white" />
        </template>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarCheckList)">
                <b-row>
                    <b-col md="12">
                        <b-tabs content-class="mt-3" variant="pills">
                            <b-tab title="Actividades Iniciales" active>
                                <b-row>
                                    <b-col md="6">
                                        <validation-provider name="proyecto" rules="required" v-slot="{errors}">
                                            <b-form-group label="Proyecto:" class="mb-2">
                                                <v-select @input="obtenerDiasProyecto" :reduce="comboProyecto =>comboProyecto.idProyecto" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosCheckList.idProyecto , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosCheckList.idProyecto" :options="comboProyecto" @search:blur="blurProyecto">
                                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Proyectos'}"> Registra aquí</router-link></span>
                                                </v-select>
                                                <div class="invalid-feedback">{{ errors[0]}}</div>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                    <b-col md="6">
                                        <validation-provider name="días de itinerario" rules="required" v-slot="{errors}">
                                            <b-form-group label="Días de itinerario:" class="mb-2">
                                                <v-select  :reduce="listaDiasItinerario =>listaDiasItinerario.id" label="dia" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosCheckList.idDia , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosCheckList.idDia" :options="listaDiasItinerario" @search:blur="blurDia">
                                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Itinerarios'}"> Registra aquí</router-link></span>
                                                </v-select>
                                                <div class="invalid-feedback">{{ errors[0]}}</div>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                    <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                       Introducción de la empresa
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[0].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[0].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                       Presentación de cocinero
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[1].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[1].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Valida experiencia en caminatas
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[2].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[2].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Explicar itinerario
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[3].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[3].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                       Explicar servicios incluidos/ no incluidos
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[4].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[4].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                               
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Recomendaciones de equipos (pax)">
                                <b-row>
                                    <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Ropa para la ruta
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[6].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[6].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                       Uso de portadores extra
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[7].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[7].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Mal de altura
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[8].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[8].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                       Medicamentos personales
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[9].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[9].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                 <b-tr>
                                                    <b-td>
                                                       Cosas para comprar en ruta
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[10].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[10].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                 <b-tr>
                                                    <b-td>
                                                       Uso adecuado de equipos
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[11].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[11].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                 <b-tr>
                                                    <b-td>
                                                      Tips recomentados para los actores del traekking
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[12].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[12].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Re-verificar detalles de trekking">
                                <b-row>
                                    <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                       Tipo de acomadción en el trekking
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[13].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[13].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Tipo de acomadción en hotel (Aguas Calientes)
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[14].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[14].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                       Tipo de matras incluidas
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[15].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[15].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                 <b-tr>
                                                    <b-td>
                                                      Cantidad de bastones
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[16].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[16].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                      Cantidad de bolsas de dormir
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[17].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[17].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                 <b-tr>
                                                    <b-td>
                                                       Cantidad de duffles
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[18].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[18].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                 <b-tr>
                                                    <b-td>
                                                       Tickets de Huayna Picchu
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[19].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[19].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                 <b-tr>
                                                    <b-td>
                                                      Tickets de bus concetur
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[20].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[20].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                 <b-tr>
                                                    <b-td>
                                                      Ticket tren
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[21].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[21].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                 <b-tr>
                                                    <b-td>
                                                       Personas que dejaran equipaje
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[22].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[22].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                              <b-tab title="Actividades finales">
                                <b-row>
                                    <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Entregar reporte de briefing operaciones
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[23].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[23].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Proporcionar información actualizada
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[24].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[24].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Verificar el plan de expedición con el equipo
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[25].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[25].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                       Manejar la documentación requeridad por expedición
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[26].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[26].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Tabla de evaluación">
                                <b-row>
                                    <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Cumplió con verificar lo establecido
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[27].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[27].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        No cumpli+o con verificar lo establecido
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[28].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[28].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                        </b-tabs>
                    </b-col>

                    <b-col md="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalRegistrarCheckList = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :show.sync="modalActualizarCheckList" size="xl" color="primario">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar check list</span></h6>
            <CButtonClose @click="modalActualizarCheckList = false" class="text-white" />
        </template>
        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarCheckList)">
                <b-row>
                    <b-col md="12">
                        <b-tabs content-class="mt-3" variant="pills">
                            <b-tab title="Inocuidad de alimentos" active>
                                <b-row>
                                    <b-col md="6">
                                        <validation-provider name="proyecto" rules="required" v-slot="{errors}">
                                            <b-form-group label="Proyecto:" class="mb-2">
                                                <v-select @input="obtenerDiasProyecto" :reduce="comboProyecto =>comboProyecto.idProyecto" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosCheckListActualizar.idProyecto , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosCheckListActualizar.idProyecto" :options="comboProyecto" @search:blur="blurProyecto">
                                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Proyectos'}"> Registra aquí</router-link></span>
                                                </v-select>
                                                <div class="invalid-feedback">{{ errors[0]}}</div>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                    <b-col md="6">
                                        <validation-provider name="días de itinerario" rules="required" v-slot="{errors}">
                                            <b-form-group label="Días de itinerario:" class="mb-2">
                                                <v-select  :reduce="listaDiasItinerario =>listaDiasItinerario.id" label="dia" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosCheckListActualizar.idDia, 'style-invalid-select is-invalid': !!errors.length}" v-model="datosCheckListActualizar.idDia" :options="listaDiasItinerario" @search:blur="blurDia">
                                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Itinerarios'}"> Registra aquí</router-link></span>
                                                </v-select>
                                                <div class="invalid-feedback">{{ errors[0]}}</div>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                      <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                       Introducción de la empresa
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[0].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[0].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                       Presentación de cocinero
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[1].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[1].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Valida experiencia en caminatas
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[2].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[2].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Explicar itinerario
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[3].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[3].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                       Explicar servicios incluidos/ no incluidos
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[4].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[4].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                               
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Recomendaciones de equipos (pax)">
                                <b-row>
                                    <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Ropa para la ruta
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[6].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[6].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                       Uso de portadores extra
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[7].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[7].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Mal de altura
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[8].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[8].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                       Medicamentos personales
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[9].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[9].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                 <b-tr>
                                                    <b-td>
                                                       Cosas para comprar en ruta
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[10].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[10].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                 <b-tr>
                                                    <b-td>
                                                       Uso adecuado de equipos
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[11].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[11].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                 <b-tr>
                                                    <b-td>
                                                      Tips recomentados para los actores del traekking
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[12].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[12].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Re-verificar detalles de trekking">
                                <b-row>
                                    <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                       Tipo de acomadción en el trekking
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[13].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[13].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Tipo de acomadción en hotel (Aguas Calientes)
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[14].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[14].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                       Tipo de matras incluidas
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[15].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[15].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                 <b-tr>
                                                    <b-td>
                                                      Cantidad de bastones
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[16].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[16].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                      Cantidad de bolsas de dormir
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[17].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[17].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                 <b-tr>
                                                    <b-td>
                                                       Cantidad de duffles
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[18].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[18].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                 <b-tr>
                                                    <b-td>
                                                       Tickets de Huayna Picchu
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[19].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[19].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                 <b-tr>
                                                    <b-td>
                                                      Tickets de bus concetur
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[20].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[20].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                 <b-tr>
                                                    <b-td>
                                                      Ticket tren
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[21].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[21].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                 <b-tr>
                                                    <b-td>
                                                       Personas que dejaran equipaje
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[22].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[22].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                              <b-tab title="Actividades finales">
                                <b-row>
                                    <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Entregar reporte de briefing operaciones
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[23].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[23].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Proporcionar información actualizada
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[24].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[24].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Verificar el plan de expedición con el equipo
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[25].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[25].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                       Manejar la documentación requeridad por expedición
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[26].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[26].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Tabla de evaluación">
                                <b-row>
                                    <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Cumplió con verificar lo establecido
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[27].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[27].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        No cumpli+o con verificar lo establecido
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[28].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[28].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                        </b-tabs>
                    </b-col>

                    <b-col lg="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalActualizarCheckList = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>
import firebase from 'firebase';
const dbCheckListCI = firebase.firestore().collection('checkListCI');
const dbProyecto = firebase.firestore().collection('proyectos');
const dbItinerario = firebase.firestore().collection('itinerarios');

export default {
    data() {
        return {
            disabled: false,
            listaCheckList: [],
            camposLote: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "idProyecto",
                    label: "Proyecto",
                    class: "text-center",
                },
                {
                    key: "cantidadChecksSi",
                    label: "Checks si cumple",
                    class: "text-center",
                },
                {
                    key: "cantidadChecksNo",
                    label: "Checks no cumple",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                }
            ],

            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            filter: null,
            filterOn: [],

            modalRegistrarCheckList: false,
            modalActualizarCheckList: false,
            comboSiNo: [{
                    value: 2,
                    text: 'SI'
                },
                {
                    value: 1,
                    text: 'No'
                },
            ],
            datosCheckList: {
                idCheckList: '',
                idCliente: '',
                idProyecto: '',
                idDia:  null,
                datosEvaluacion: [{
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, 
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
               
                
                ]
            },
            datosCheckListActualizar: {
                idCheckList: '',
                idCliente: '',
                idProyecto: '',
                idDia: null,
                datosEvaluacion: [{
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, 
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
               
                
                ]
            },
            comboProyecto: [],
            listaDiasItinerario: [],
            arrayDiasItinerario: [],
        }
    },
    methods: {
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        blurProyecto() {
            this.computedForm.refs.proyecto.validate();
        },
        blurProyectoActualizar() {
            this.computedFormActualizar.refs.proyecto.validate();
        },
        blurDia() {
            this.computedForm.refs['días de itinerario'].validate();
        },
        blurDiaActualizar() {
            this.computedFormActualizar.refs['días de itinerario'].validate();
        },
        obtenerDiasProyecto(e) {
               this.datosCheckListActualizar.idDia = null;
            this.datosCheckList.idDia = null;
            this.listaDiasItinerario = this.arrayDiasItinerario.filter(x => x.idProyecto == e);
        },
       
        listarProyectos() {
            let me = this;
            dbProyecto
                .where("estado", "==", 2)
                .where('idCliente', '==', String(me.datosCheckList.idCliente))
                .get()
                .then((querySnapshot) => {
                    me.comboProyecto = [];
                    querySnapshot.forEach((doc) => {
                        me.comboProyecto.push({
                            idProyecto: doc.id,
                            nombre: doc.data().nombre,
                            fechaCreacion: doc.data().fechaCreacion,
                            fechaModificacion: doc.data().fechaModificacion,
                        });
                    });
                    me.comboProyecto.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0));
                    me.listarCheckList();
                    me.listarItinerario();
                })
                .catch((error) => {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarItinerario() {
            let me = this;
            dbItinerario
                .where("estado", "==", 2)
                .where('idCliente', '==', String(me.datosCheckList.idCliente))
                .onSnapshot((querySnapshot) => {
                    me.arrayDiasItinerario = [];
                    let itinerarios = [];
                    querySnapshot.forEach((doc) => {
                        itinerarios.push({
                            idItinerario: doc.id,
                            idProyecto: doc.data().idProyecto,
                            listaDias: doc.data().listaDias,
                        });
                    });
                    itinerarios.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0))

                    for (const i in itinerarios) {
                        for (const j in itinerarios[i].listaDias) {
                            me.arrayDiasItinerario.push({
                                idItinerario: itinerarios[i].idItinerario,
                                idProyecto: itinerarios[i].idProyecto,
                                dia: itinerarios[i].listaDias[j].nombre,
                                id: j,
                            });
                        }
                    }
                });
        },
        listarCheckList() {
            let me = this;
            dbCheckListCI
                .where("estado", "==", 2)
                .where('idCliente', '==', String(me.datosCheckList.idCliente))
                .onSnapshot((querySnapshot) => {
                    me.listaCheckList = [];
                    querySnapshot.forEach((doc) => {
                        me.listaCheckList.push({
                            idCheckList: doc.id,
                            idCliente: doc.data().idCliente,
                            idProyecto: doc.data().idProyecto,
                            idDia: doc.data().idDia,
                            datosEvaluacion: doc.data().datosEvaluacion,
                            estado: doc.data().estado,
                            fechaCreacion: doc.data().fechaCreacion,
                            fechaModificacion: doc.data().fechaModificacion,
                        });
                    });
                    me.listaCheckList.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0))
                });
        },
        registrarCheckList() {
            let me = this;
            me.disabled = true;
            dbCheckListCI.add({
                    idCliente: me.datosCheckList.idCliente,
                    idProyecto: me.datosCheckList.idProyecto,
                    idDia: me.datosCheckList.idDia,
                    datosEvaluacion: me.datosCheckList.datosEvaluacion,
                    estado: 2,
                    fechaCreacion: firebase.firestore.Timestamp.fromDate(new Date()),
                    fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                })
                .then((docRef) => {
                    //console.log("Creado correctamente ID: ", docRef.id);
                    me.swat('success', 'Registrado satisfactoriamente')
                    me.modalRegistrarCheckList = false
                    me.disabled = false;
                })
                .catch((error) => {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });

        },
        actualizarCheckList() {
            let me = this;
            me.disabled = true;
            dbCheckListCI.doc(me.datosCheckListActualizar.idCheckList).update({
                    idCliente: me.datosCheckListActualizar.idCliente,
                    idProyecto: me.datosCheckListActualizar.idProyecto,
                    idDia: me.datosCheckListActualizar.idDia,
                    datosEvaluacion: me.datosCheckListActualizar.datosEvaluacion,
                    fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                })
                .then(() => {
                    //console.log("Actualizado correctamente");
                    me.swat('success', 'Modificado satisfactoriamente')
                    me.modalActualizarCheckList = false
                    me.disabled = false;
                })
                .catch((error) => {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });

        },
        eliminarCheckList(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el check list?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    dbCheckListCI.doc(param.item.idCheckList).update({
                            estado: 1,
                            fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                        })
                        .then(() => {
                            me.swat('success', 'Eliminado satisfactoriamente')
                            me.disabled = false;
                        })
                        .catch((error) => {
                            me.disabled = false;
                            me.swat('error', 'Algo salió mal!')
                        });

                }
            })
        },
        abrirModalActualizarCheckList(param) {
            this.datosCheckListActualizar.idCheckList = param.item.idCheckList;
            this.datosCheckListActualizar.idProyecto = param.item.idProyecto;
            this.obtenerDiasProyecto(this.datosCheckListActualizar.idProyecto);
            this.datosCheckListActualizar.idDia = param.item.idDia;
            this.datosCheckListActualizar.datosEvaluacion = param.item.datosEvaluacion;
            this.modalActualizarCheckList = true
        },

        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
        resetModalRegistrarCheckList() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosCheckList.idProyecto = '';
            this.datosCheckList.datosEvaluacion= [{
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, 
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                {
                    evaluacion: '',
                    observaciones: ''
                },
                
                ];

        },
        resetModalActualizarCheckList() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
        },
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedFormActualizar() {
            return this.$refs.observer2;
        },

    },
    watch: {
        modalRegistrarCheckList: function (val) {
            if (val == false) {
                this.resetModalRegistrarCheckList();
            }
        },
        modalActualizarCheckList: function (val) {
            if (val == false) {
                this.resetModalActualizarCheckList();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosCheckList.idCliente = this.datosCheckListActualizar.idCliente = user.idCliente;
            //this.listarCheckList();
            this.listarProyectos();
        }
    }

}
</script>
